import React from 'react';
import './index.css'; // we will create this file later for styling

const LandingPage = () => {
    if (!sessionStorage.getItem('easterEggShown')) {
        console.log("%cNice move, You're a curious one and we love it! \n%cDrop us a line if you're up for some fun!", "color: #d4af37; font-size: 20px; font-weight: bold;", "color: #4169E1; font-size: 20px; font-weight: bold;");

        console.log("%cvalue@tcorn.farm", "color: #d4af37; font-size: 16px; font-weight: bold;");


        sessionStorage.setItem('easterEggShown', 'true');
    }

    return (
        <div className="landing-page">
            <p className="landing-text">making cool stuff and having fun <br/> at T CORN FARM
                <br/>
                have value & need resources?
                <br/>
                <a  href="mailto:value@tcorn.farm?subject=Value@Scale&body=Hey%20take%20a%20look%20into%20my%20pitch%20deck.">value@tcorn.farm</a>
            </p>
        </div>
    );
}

export default LandingPage;
